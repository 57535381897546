<template>

<app-page :locked="is.saving || is.deleting || is.copying">

	<app-page-head :title="title" :back="$route.meta.back">

		<app-page-head-action v-if="hasReferences" :loading="is.copying" :disabled="isNew" icon="copy" tooltip="Copy" v-on:click.native="onCopyClick" />
		<app-page-head-action v-if="canRemove" :disabled="isNew" icon="remove.text" tooltip="Remove" v-on:click.native="onRemoveClick" />
		<app-page-head-divide v-if="hasReferences || canRemove" />
		<app-page-head-action :disabled="!isDirty" icon="undo" tooltip="Undo changes" v-on:click.native="onUndoClick" />
		<app-page-head-action :disabled="!isDirty" :loading="is.saving" icon="save" tooltip="Save changes" v-on:click.native="onSaveClick" />
		<app-page-head-action v-if="!isNew" :loading="is.deleting" :disabled="isNew" :danger="true" icon="delete" tooltip="Delete" v-on:click.native="onDeleteClick" />
		
	</app-page-head>

	<app-page-content :is-grown="true">

		<app-page-content-section :is-padded="true" :is-divided="true" v-if="isNew">

			<app-input-toggle v-model="setup" :full-width="true" :options="setupOptions" />
			<app-input-suggest v-if="setup !== $constants.text.setup.blank" v-model="source" :icon="$constants.text.icon" store="texts" placeholder="Search content blocks..." />

		</app-page-content-section>

		<app-notice v-if="hasReferences">
			
			This text is used by <template v-if="groupReferencesCount">{{ groupReferencesCount }} group<template v-if="groupReferencesCount > 1">s</template></template><template v-if="locationReferencesCount"> <template v-if="groupReferencesCount"> and </template>{{ locationReferencesCount }} location<template v-if="locationReferencesCount > 1">s</template></template>. 
			Any changes will update across all groups or locations that use it. To only edit the text for this particular group or location, click the <app-icon icon="copy" :inline="true" /> button and then proceed to edit.

		</app-notice>

		<app-page-content-section :is-padded="true">

			<app-input-text :disabled="!canEdit" v-model="model.title" :validation="$v.model.title" label="Name" placeholder="Enter name" />
			<app-input-select :disabled="!canEdit" v-model="model.type" :validation="$v.model.type" label="Type" :options="types" :icons="$constants.text.icon" placeholder="Select type" />
			<app-input-text :disabled="!canEdit" v-model="model.content" :autogrow="true" :label="(isEmbed) ? 'Embed code' : 'Text'" placeholder="Enter text" v-if="hasContent" />
			<app-input-text :disabled="!canEdit" v-model="model.content" :toolbar="['bold', 'italic', 'underline', 'link']" :autogrow="true" :rich="true" label="Text" placeholder="Enter text" v-if="hasRichContent" />
			<app-input-text :disabled="!canEdit" v-model="model.url" label="URL" placeholder="Enter url" v-if="hasUrl" />
			<app-input-image :disabled="!canEdit" v-model="model.image" label="Image" v-if="hasImage" />
			<app-input-suggest :disabled="!canEdit" v-model="model.group" store="groups" image="image" label="Group" placeholder="Enter group name" v-if="hasGroup" />
			<app-input-text :disabled="!canEdit" v-model="model.content" label="Text" placeholder="Enter text" v-if="hasText" />
			<app-input-suggest :disabled="!canEdit" v-model="model.location" store="locations" :show-all="true" image="logo" label="Location" placeholder="Select location" v-if="hasLocation" />
			<app-input-blank label="Preview" v-if="model.type">

				<app-text :item="previewModel" :index="0" :is-first="true" :is-last="true" :is-preview="true" />

			</app-input-blank>

		</app-page-content-section>

		<app-page-content-section title="Groups using" v-if="!isNew && groupReferencesCount">

			<com-group :item="group" v-for="group in groupReferences" :key="group.id" />

		</app-page-content-section>

		<app-page-content-section title="Locations using" v-if="!isNew && locationReferencesCount">

			<com-location :item="location" v-for="location in locationReferences" :key="location.id" />

		</app-page-content-section>

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixForm from '@/mixin/form'
import Vue from 'vue'
import { required, maxLength, minValue} from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	components: {
		'com-location': () => import('./locations/Item'),
		'com-group': () => import('./groups/Item')
	},

	data: function() {

		return {
			is: {
				copying: false
			},
			setup: 1,
			setupOptions: {
				1: 'Existing',
				2: 'Duplicate',
				3: 'New'
			},
			source: 0,
			model: {
				id: 0,
				type: 0,
				title: '',
				content: '',
				group: 0,
				location: 0,
				url: '',
				image: ''
			},
			replace: false,
			types: {
				1: 'Title',
				2: '"Category" bullet',
				3: '"No" bullet',
				4: '"Check" bullet',
				14: '"Check" columns',
				5: '"Star" bullet',
				7: '"Pin" bullet',
				8: '"Link" bullet',
				6: 'Link to Location',
				9: 'Link to Group...',
				15: 'Connected Groups',
				10: 'Image',
				11: 'Flush text',
				12: 'Embed',
				13: 'Phone',
			},
			param: 'text',
			noun: 'Block',
			store: 'texts'
		}

	},

	validations: {
		model: {
			title: {
				required,
				maxLength: maxLength(64)
			},
			type: {
				required,
				minValue: minValue(1)
			}
		}
	},

	watch: {

		source: function() {

			if (this.source) {

				Vue.set(this, 'model', this.$util.clone(this.$store.getters['texts/find'](this.source)))

			} else {

				this.model = {
					id: 0,
					type: 0,
					title: '',
					content: '',
					image: '',
					url: '',
					group: 0,
					location: 0
				}

			}

		},

		setup: function() {

			if (this.setup === this.$constants.text.setup.blank) this.source = 0

		}

	},

	computed: {

		isNew: function() {

			return this.$route.params[this.param] === 'new' 

		},

		previewModel: function() {

			return {
				id: this.model.id,
				type: parseInt(this.model.type),
				title: this.model.title,
				image: this.model.image,
				url: this.model.url,
				content: this.model.content || ((this.hasContent) ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' : false),
				group: (this.$store.getters['groups'].length && !this.model.group) ? 0 : this.model.group,
				location: (this.$store.getters['locations'].length && !this.model.location) ? 0 : this.model.location
			}

		},

		title: function() {

			return (this.isNew) ? 'Add ' + this.noun : 'Edit ' + this.noun

		},

		canRemove: function() {

			return (this.$route.meta.byGroup || this.$route.meta.byLocation) && !this.isNew

		},

		canEdit: function() {

			return this.setup !== this.$constants.text.setup.use || !this.isNew

		},

		isEmbed: function() {

			return this.model.type === this.$constants.text.type.embed 

		},

		hasContent: function() {

			return this.$_.contains([this.$constants.text.type.checklist, this.$constants.text.type.connectedgroups, this.$constants.text.type.title, this.$constants.text.type.embed, this.$constants.text.type.phone, this.$constants.text.type.link], this.model.type)

		},

		hasRichContent: function() {

			return this.$_.contains([this.$constants.text.type.tick, this.$constants.text.type.text, this.$constants.text.type.cross, this.$constants.text.type.check, this.$constants.text.type.star, this.$constants.text.type.pin], this.model.type)

		},

		hasText: function() {

			return this.$_.contains([], this.model.type)

		},

		hasImage: function() {

			return this.$_.contains([this.$constants.text.type.image], this.model.type)

		},

		hasUrl: function() {

			return this.$_.contains([this.$constants.text.type.link], this.model.type)

		},

		hasGroup: function() {

			return this.$_.contains([this.$constants.text.type.cross, this.$constants.text.type.group], this.model.type)

		},

		hasLocation: function() {

			return this.$_.contains([this.$constants.text.type.location], this.model.type)

		},

		referencesCount: function() {

			return this.groupReferencesCount + this.locationReferencesCount

		},

		hasReferences: function() {

			return !this.isNew && this.referencesCount && (this.$route.meta.byGroup || this.$route.meta.byLocation)

		},

		groupReferences: function() {

			return this.$_.filter(this.$store.getters['groups'], function(item) {

				return this.$_.contains(item.texts, this.model.id)

			}.bind(this))

		},

		groupReferencesCount: function() {

			return this.groupReferences.length

		},

		locationReferences: function() {

			return this.$_.filter(this.$store.getters['locations'], function(item) {

				return this.$_.contains(item.texts, this.model.id)

			}.bind(this))

		},

		locationReferencesCount: function() {

			return this.locationReferences.length

		}

	},

	methods: {

		onSaveClick: async function() {

			this.$v.$touch()

			if (this.$v.$invalid) {

				this.is.error = true

			} else {

				if (this.isNew) {

					if (this.setup === this.$constants.text.setup.use) {
					
						this.updateOriginal()

						this.goBack({
							textInsert: {
								id: this.model.id,
								position: this.$route.query.position || 0
							}
						})

					} else {

						this.is.saving = true

						this.model.id = false
						
						this.$store.dispatch(this.store + '/save', this.model).then(function(response) {
					
							this.updateOriginal()

							this.goBack({
								textInsert: {
									id: response.id,
									position: this.$route.query.position || 0
								}
							})

						}.bind(this))

					}

				} else {

					this.is.saving = true

					await this.$store.dispatch(this.store + '/save', this.model)
					
					this.updateOriginal()

					this.goBack((this.replace) ? {
						textReplace: {
							from: this.replace,
							to: this.model.id
						}
					} : {})

				}
				
			}

		},

		onDeleteClick: async function() {

			this.clearStash()

			this.is.deleting = true

			await this.$store.dispatch(this.store + '/delete', this.model)

			this.goBack({
				textDelete: this.model.id
			})

		},

		onUndoClick: function() {

			this.source = 0
			this.setup = this.$constants.text.setup.use

			if (this.replace) {

				this.original.id = this.replace
				this.replace = false

			}

			Vue.set(this, 'model', this.$util.clone(this.original))

		},

		onCopyClick: function() {

			this.is.copying = true

			this.replace = this.model.id

			this.model.id = false

			this.$store.dispatch(this.store + '/save', this.model).then(function(response) {

				this.model.id = response.id
				this.original.id = response.id

				this.updateOriginal()

				this.is.copying = false

			}.bind(this))

		},

		onRemoveClick: function() {

			this.goBack({
				textRemove: this.model.id
			})

		}

	}

}

</script>

<style scoped>

</style>
